import { IoGlobeOutline, IoCodeWorking, IoLogoGithub, IoLogoLinkedin, IoLogoTwitter, IoLogoYoutube, IoLink, IoLogoLaravel, IoLogoReact, IoLogoInstagram, IoLogoPython } from "react-icons/io5";
import { FaPhp } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";

import img_store1 from "./images/s1.jpg"
import img_store2 from "./images/s2.jpg"
import img_store3 from "./images/s3.jpg"
import img_store4 from "./images/s4.jpg"
import img_store5 from "./images/s5.jpg"
import img_ooapas1 from "./images/to1.jpg"
import img_ooapas2 from "./images/to2.jpg"
import img_ooapas3 from "./images/to3.jpg"
import img_ooapas4 from "./images/to4.jpg"
import img_ooapas5 from "./images/to5.jpg"
import img_str1 from "./images/wps1.jpg"
import img_str2 from "./images/wps2.jpg"
import img_str3 from "./images/wps3.jpg"
import img_str4 from "./images/wps4.jpg"
import img_tecnol1 from "./images/1.jpg"
import img_tecnol2 from "./images/2.jpg"
import img_tecnol3 from "./images/3.jpg"
import img_tecnol4 from "./images/4.jpg"
import img_tecnol5 from "./images/5.jpg"
import img_tav1 from "./images/t1.jpg"
import img_tav2 from "./images/t2.jpg"
import img_tav3 from "./images/t3.jpg"
import img_tav4 from "./images/t4.jpg"
import img_gdclimex1 from "./images/g1.jpg"
import img_gdclimex2 from "./images/g2.jpg"
import img_gdclimex3 from "./images/g3.jpg"
import img_gdclimex4 from "./images/g4.jpg"
import img_python from "./images/python.png"
import img_aws from "./images/aws.png"
import img_mysql from "./images/mysql.png"
import img_react from "./images/react.png"
import img_laravel from "./images/laravel.png"
import img_javascript from "./images/javascript.png"
import img_php from "./images/php.png"
import img_docker from "./images/docker.png"
import img_plsql from "./images/plsql.svg"
import img_rabbit from "./images/RabbitMQ.png"
import img_java from "./images/java.png"
import img_flask from "./images/flask.png"
import img_django from "./images/django.png"
import img_postgress from "./images/postgress.png"


export const Experience = [
    {
        id: 0,
        date: "2024 - ...",
        iconsSrc: <IoCodeWorking />,
        title: "Sr. Backend Developer",
        company: "Upax",
        location: "Remoto",
        description: "Desarrollo de microservicios AWS Python - Java",
        list_habilities: [
            "Microservicios",
            "Principios Clean Code",
            "Flask",
            "Pruebas unitárias de componentes de integración y E2E",
            "Proceso BDD",
            "Estandar de codigo PEP8",
            "Gitflow",
            "Patrones de diseño",
            "Programación funcional y orientada a objetos",
            "CI/CD",
            "OAuth",
            "api-key",
            "Desarrollo por capas",
            "Arquitectura desacoplada"
        ],
        list_implement_techs: [
            "Cognito",
            "SQS",
            "SMS",
            "S3",
            "Lambdas",
            "API Rest",
        ],
        list_tech: "Python / Java / AWS / Flask / Aurora / Postgress",
        project: "Promo Espacio",
        links: [
            {
                "id": 1, 
                "icon": <IoLink className="text-yellow-400 text-3x1 cursor-pointer" />, 
                "url": "https://www.facebook.com/PromoEspacio/?locale=es_LA",
                "description": "Red Social"
            },
        ]
    },
    {
        id: 1,
        date: "2023 - 2024",
        iconsSrc: <IoCodeWorking />,
        title: "Backend Developer",
        company: "Upax",
        location: "Remoto",
        description: "Desarrollo de microservicios AWS Python Django",
        list_habilities: [
            "Microservicios",
            "Principios Clean Code con Django",
            "Pruebas unitárias de componentes",
            "Proceso TDD",
            "Estandar de codigo PEP8",
            "Analisis estatico lintern",
            "Gitflow",
            "Programación orientada a objetos",
            "Desarrollo por capas",
            "Arquitectura Docker compose EC2"
        ],
        list_implement_techs: [
            "RabbitMQ",
            "SDK sincronizacion de bases de datos",
            "S3",
            "SDK herramientas de validacion",
        ],
        list_tech: "Python / AWS / Django / Postgress",
        project: "SIHA",
        links: []
    },
    {
        id: 2,
        date: "2022 - 2023",
        iconsSrc: <IoCodeWorking />,
        title: "Backend Developer",
        company: "Upax",
        location: "Remoto",
        description: "Desarrollo de microservicios AWS Python",
        list_habilities: [
            "Microservicios",
            "Principios Clean Code",
            "Flask",
            "Pruebas unitárias de componentes de integración y E2E",
            "Proceso BDD",
            "Estandar de codigo PEP8",
            "Analisis estatico code guru",
            "Gitflow",
            "Patrones de diseño",
            "Programación funcional y orientada a objetos",
            "CI/CD",
            "OAuth",
            "api-key",
            "Desarrollo por capas",
            "Arquitectura desacoplada"
        ],
        list_implement_techs: [
            "Cognito",
            "SQS",
            "SMS",
            "S3",
            "lambda.invoke",
            "API Rest",
            "API WebSocket",
            "Sentry",
            "Veritatech",
            "Google directions"
        ],
        list_tech: "Python / AWS / Flask / Aurora / Postgress",
        project: "Bazlisto",
        links: [
            {
                "id": 1, 
                "icon": <IoLink className="text-yellow-400 text-3x1 cursor-pointer" />, 
                "url": "https://twitter.com/RicardoBSalinas/status/1572657011892064257",
                "description": "Prensa"
            },
            {
                "id": 1, 
                "icon": <IoLink className="text-pink-400 text-3x1 cursor-pointer" />, 
                "url": "https://www.canva.com/design/DAFmStMWlCc/WIJykCFgTWtQd9NtowX04A/edit?utm_content=DAFmStMWlCc&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
                "description": "Arquitectura de Microservicios"
            },
        ]
    },
    {
        id: 3,
        date: "2022 - 2022",
        iconsSrc: <IoCodeWorking />,
        title: "Backend Developer",
        company: "Medio Melon",
        location: "Remoto",
        description: "Desarrollo de api trading python IBKR",
        list_habilities: [
            "Trading",
            "Despliegues EC2",
            "Bases de datos no relacionales",
            "Gitflow"
        ],
        list_implement_techs: [
            "IBKR",
            "EC2",
            "Nginx",
        ],
        list_tech: "Python / Docker / FastAPI / Redis / MongoDB",
        project: "ApiTrading",
        links: []
    },
    {
        id: 4,
        date: "2021 - 2022",
        iconsSrc: <IoCodeWorking />,
        title: "Backend Developer",
        company: "Medio Melon",
        location: "Remoto",
        description: "Desarrollo de microservicios python",
        list_habilities: [
            "Microservicios",
            "Diseño Api rest",
            "Bases de datos relacionales",
            "Gitflow",
            "Canban",
            "Programación orientada a objetos",
            "SAM"
        ],
        list_implement_techs: [
            "Cognito",
            "SQS",
            "SMS"
        ],
        list_tech: "Python / Docker / PlSQL / SWAGGER",
        project: "Baz encuentro - Iglesia digital ",
        links: [
            {
                "id": 1, 
                "icon": <IoLink className="text-yellow-400 text-3x1 cursor-pointer" />, 
                "url": "https://www.milenio.com/politica/comunidad/lanzan-app-iglesia-digital-acercar-fieles-iglesia",
                "description": "Prensa"
            },
            {
                "id": 1, 
                "icon": <IoLink className="text-yellow-400 text-3x1 cursor-pointer" />, 
                "url": "https://www.canva.com/design/DAFmT4-itOQ/2TTAJG9vUG1voa-cC1njNA/edit?utm_content=DAFmT4-itOQ&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
                "description": "Arquitectura de Microservicios"
            }            
        ]
    },
    {
        id: 5,
        date: "2020 - 2021",
        iconsSrc: <IoCodeWorking />,
        title: "Jefe de oficina de Automatización",
        company: "OOAPAS Morelia",
        location: "Morelia, Michoacán",
        description: "Desarrollo SCADA y sistemas de inventario y monitoreo de incidencias",
        list_habilities: [
            "react hooks",
            "Protocolos RS485 y HART",
            "Bases de datos relacionales",
            "Instrumentación",
            "Electrónica",
            "Planificación y estrategias de rehabilitación"
        ],
        list_implement_techs: [
            "AppContext",
            "Leaflet",
            "React Query",
            "google-charts",
            "SWAGGER",
            "Materialize",
            "TailwindCSS",
            "SAS"
        ],
        list_tech: "Laravel / React / SQLServer / Mysql",
        project: "SCADA OOAPAS",
        links: [
            {
                "id": 1, 
                "icon": <IoLogoReact className="text-blue-500 text-3x1 cursor-pointer" />, 
                "url": "http://www.telemetooapas.manserempr.com.mx/",
                "description": "Pagina demo"
            }            
        ]
    },
    {
        id: 6,
        date: "2019 - A la fecha",
        iconsSrc: <IoCodeWorking />,
        title: "CEO de empresa de Domótica y Tecnología",
        company: "TECNORRIL",
        location: "Morelia, Michoacán",
        description: "Desarrollo de prototipos de monitoreo y sistemas de visualización, tiendas digitales",
        list_habilities: [
            "Administración de servidores",
            "Emails",
            "Bases de datos relacionales",
            "Instrumentación",
            "Electronica",
            "Creación de prototipos",
            "Diseño e impresión 3d",
        ],
        list_implement_techs: [
            "AppContext",
            "Leaflet",
            "React Query",
            "google-charts",
            "Materialize",
            "TailwindCSS",
            "SAS"
        ],
        list_tech: "Laravel / React / C / Mysql / PHP / Javascript",
        project: "TECNORRILL",
        links: [
            {
                "id": 1, 
                "icon": <IoLogoReact className="text-blue-500 text-3x1 cursor-pointer" />, 
                "url": "https://stores.tecnorrill.com/stores",
                "description": "Tiendas electronicas 2.0"
            },
            {
                "id": 2, 
                "icon": <IoLogoLaravel className="text-red-500 text-3x1 cursor-pointer" />, 
                "url": "https://services.manserempr.com.mx/data/stores/public/",
                "description": "Backend y administración de tiendas electrónicas 2.0"
            },
            {
                "id": 3, 
                "icon": <IoLink className="text-yellow-400 text-3x1 cursor-pointer" />, 
                "url": "http://soft.platzi.com.es.manserempr.com.mx/index.php/2021/07/18/stores-tecnorrill/",
                "description": "Notas en blog de tiendas electrónicas 2.0"
            },
            {
                "id": 1, 
                "icon": <FaPhp className="text-orange-700 text-3x1 cursor-pointer" />, 
                "url": "https://pedidos.manserempr.com.mx/set.php",
                "description": "Tiendas electronicas 1.0"
            },
            {
                "id": 2, 
                "icon": <IoLogoReact className="text-blue-500 text-3x1 cursor-pointer" />, 
                "url": "https://delivery.manserempr.com.mx/",
                "description": "Pagina de administración de entregas tiendas 1.0"

            },
            {
                "id": 3, 
                "icon": <IoLogoLaravel className="text-red-500 text-3x1 cursor-pointer" />, 
                "url": "https://pedidos.manserempr.com.mx/edit-sale/public",
                "description": "Pagina de administración de pedidos tiendas 1.0"
            },
            {
                "id": 1, 
                "icon": <IoGlobeOutline className="text-blue-700 text-3x1 cursor-pointer" />, 
                "url": "http://manserempr.com.mx/PAGINA-DE-INICIO/",
                "description": "Pagina principal de Tecnorrill"
            },
            {
                "id": 2, 
                "icon": <FaPhp className="text-red-500 text-3x1 cursor-pointer" />, 
                "url": "http://manserempr.com.mx/MONITOREO/",
                "description": "Pagina principal de monitoreo"
            }            
        ]
    },
    {
        id: 7,
        date: "2010 - 2011",
        iconsSrc: <IoCodeWorking />,
        title: "Colaborador en investigación",
        company: "UMSNH",
        location: "Morelia, Michoacán",
        description: "Desarrollo de un gestor de datos climatologicos",
        list_habilities: [
            "Programación funcional",
            "Estadistica",
            "Numerical Recipes",
            "Implementación de librerias graficas",
            "Trabajo en equipo",
            "Investigación",
            "Analisis Geospacial"
        ],
        list_implement_techs: [
            "DCL",
            "Encriptación",
        ],
        list_tech: "AutoLisp / VB / VBA / Mysql / PHP / C",
        project: "GDCLIMEX",
        links: [
            {
                "id": 1, 
                "icon": <IoLogoGithub className="text-textBase text-3x1 cursor-pointer" />, 
                "url": "https://github.com/Manenpren/GDCLIMEX",
                "description": "Repositorio de GitHub"
            }
        ],
    },    
]

export const Projects = [
    {
        id: 1,
        name: "Tecnorril Tiendas 2.0",
        techs: "ReactJs / Laravel / TailWind / Materialize",
        images: [img_store1, img_store2, img_store3, img_store4, img_store5],
        links: [
            {
                "id": 1, 
                "icon": <IoLogoReact className="text-blue-500 text-3x1 cursor-pointer" />, 
                "url": "https://stores.tecnorrill.com/stores"
            },
            {
                "id": 2, 
                "icon": <IoLogoLaravel className="text-red-500 text-3x1 cursor-pointer" />, 
                "url": "https://services.manserempr.com.mx/data/stores/public/"
            },
            {
                "id": 3, 
                "icon": <IoLink className="text-yellow-400 text-3x1 cursor-pointer" />, 
                "url": "http://soft.platzi.com.es.manserempr.com.mx/index.php/2021/07/18/stores-tecnorrill/"
            }
        ],
        date: "Julio de 2021",
        description: "Tecnorril tiendas, la versión recargada de las tiendas online para pequeños negocios con un poderoso gestor de tiendas para cada cliente, agregando ademas programación con react-hooks y laravel 8.0+ con jetstream"
    },
    {
        id: 2,
        name: "SCADA OOAPAS Morelia",
        techs: "ReactJs / Laravel / PHP / C / Mysql",
        images: [img_ooapas1, img_ooapas2, img_ooapas3, img_ooapas4, img_ooapas5],
        links: [
            {
                "id": 1, 
                "icon": <IoLogoReact className="text-blue-500 text-3x1 cursor-pointer" />, 
                "url": "http://www.telemetooapas.manserempr.com.mx/"
            }
        ],
        date: "Enero de 2021",
        description: "Sistema SCADA organismo operador agua potable, ejecución de sistemas de visualización, monitoreo, y rehabilitación de la instrumentación, alertas automáticas y prototipos remotos de medición, CRUDS, incidencias de campo en tiempo real, apis y bases de datos fuente del sistema alertas automaticas y mas!..."
    },
    {
        id: 3,
        name: "Tiendas Tecnorril 1.0",
        techs: "PHP / JavaScript / Mysql / Laravel / ReactJs",
        images: [img_str1, img_str2, img_str3, img_str4],
        links: [
            {
                "id": 1, 
                "icon": <FaPhp className="text-orange-700 text-3x1 cursor-pointer" />, 
                "url": "https://pedidos.manserempr.com.mx/set.php"
            },
            {
                "id": 2, 
                "icon": <IoLogoReact className="text-blue-500 text-3x1 cursor-pointer" />, 
                "url": "https://delivery.manserempr.com.mx/"
            },
            {
                "id": 3, 
                "icon": <IoLogoLaravel className="text-red-500 text-3x1 cursor-pointer" />, 
                "url": "https://pedidos.manserempr.com.mx/edit-sale/public"
            },            
        ],
        date: "Marzo de 2020",
        description: "Tecnorril tiendas, se crearon tiendas online en apoyo a negocios pequeños locales con pocas ventas, utilizando tecnologias PHP, Laravel, React.js, base de datos Mysql"
    },
    {
        id: 4,
        name: "Tecnorril Tecnologia y Domotica",
        techs: "PHP / JavaScript / C / Mysql",
        images: [img_tecnol1, img_tecnol2, img_tecnol3, img_tecnol4, img_tecnol5],
        links: [
            {
                "id": 1, 
                "icon": <IoGlobeOutline className="text-blue-700 text-3x1 cursor-pointer" />, 
                "url": "http://manserempr.com.mx/PAGINA-DE-INICIO/"
            },
            {
                "id": 2, 
                "icon": <FaPhp className="text-red-500 text-3x1 cursor-pointer" />, 
                "url": "http://manserempr.com.mx/MONITOREO/"
            }
        ],
        date: "Junio de 2019",
        description: "Tecnorril tiendas, se crearon tiendas online en apoyo a negocios pequeños locales con pocas ventas, utilizando tecnologias PHP, Laravel, React.js, base de datos Mysql"
    },    
    {
        id: 5,
        name: "GDCLIMEX (tesis investigacion)",
        techs: "Autolisp / C++ / VisualBasic / PHP / VBA",
        images: [img_gdclimex1, img_gdclimex2, img_gdclimex3, img_gdclimex4],
        links: [
            {
                "id": 1, 
                "icon": <IoLogoGithub className="text-textBase text-3x1 cursor-pointer" />, 
                "url": "https://github.com/Manenpren/GDCLIMEX"
            }
        ],
        date: "Febrero de 2010",
        description: "Gestor de datos climatológicos Mexicanos, un programa donde desarrolle un gestor de datos climatologicos en autocad, con el desarrollo de una pequeña libreria grafica para representación de los datos y procesamiento estadístico, ademas de analisis estadisticos bastante interesantes."
    },    
    {
        id: 6,
        name: "TRANSITO DE AVENIDAS (colaboración en investigación)",
        techs: "VB",
        images: [img_tav1, img_tav2, img_tav3, img_tav4],
        links: [
            {
                "id": 1, 
                "icon": <IoLink className="text-yellow-400 text-3x1 cursor-pointer" />, 
                "url": "https://www.gob.mx/cms/uploads/attachment/file/58558/Transito-de-avenidas.pdf"
            }
        ],
        date: "Agosto de 2014",
        description: "Colaboración con el IMTA para efectuar un programa para realizar un calculo del transito de Avenidas en vasos con algunas consideraciones especiales para un calculo mas fino que los programas comerciales, fue utilizado como base pare el diseño de una gran presa."
    },    
]

export const SocialLinks = [
    {
        id: 1,
        iconsSrc: <IoLogoLinkedin className="text-blue-800 text-3x1 cursor-pointer"/>,
        name: "LinkedIn",
        link: "https://www.linkedin.com/in/manserem/",
    },
    {
        id: 2,
        iconsSrc: <TfiWrite className="text-green-400 text-3x1 cursor-pointer"/>,
        name: "My Blog",
        link: "https://soft.platzi.com.es.manserempr.com.mx/",
    },
    {
        id: 3,
        iconsSrc: <IoLogoGithub className="text-textBase text-3x1 cursor-pointer" />,
        name: "Github",
        link: "https://github.com/Manenpren",
    },
    {
        id: 4,
        iconsSrc: <IoLogoTwitter className="text-blue-500 text-3x1 cursor-pointer"/>,
        name: "Twitter",
        link: "https://twitter.com/MANUELS48645830",
    },
    //{
    //    id: 4,
    //   iconsSrc: <IoLogoInstagram className="text-red-500 text-3x1 cursor-pointer"/>,
    //    name: "Instagram",
    //    link: "https://www.instagram.com/manserem/",
    //},    
]

export const Tecnologies = [
    {
        "id": 1, 
        "img_src": img_python, 
        "name": "Python"
    },
    {
        "id": 0, 
        "img_src": img_java, 
        "name": "Java"
    },
    {
        "id": 6, 
        "img_src": img_javascript, 
        "name": "Javascript"
    },
    {
        "id": 7, 
        "img_src": img_php, 
        "name": "PHP"
    },
    {
        "id": 2, 
        "img_src": img_aws, 
        "name": "AWS"
    },
    {
        "id": 20, 
        "img_src": img_flask, 
        "name": "Flask"
    },
    {
        "id": 21, 
        "img_src": img_django,
        "name": "Django"
    },
    {
        "id": 4, 
        "img_src": img_react, 
        "name": "ReactJs"
    },
    {
        "id": 5, 
        "img_src": img_laravel, 
        "name": "Laravel"
    },
    {
        "id": 3,
        "img_src": img_mysql, 
        "name": "Mysql"
    },
    {
        "id": 9, 
        "img_src": img_plsql, 
        "name": "PlSQL"
    },
    {
        "id": 30, 
        "img_src": img_postgress, 
        "name": "PlSQL"
    },
    {
        "id": 8, 
        "img_src": img_docker, 
        "name": "Docker - Compose"
    },
    {
        "id": 10,
        "img_src": img_rabbit, 
        "name": "RabbitMQ"
    }
]

