import Spline from '@splinetool/react-spline';
import { useState } from 'react';
import { IoMenu } from 'react-icons/io5'

import Logo from './images/logo.png'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Experience, Projects, SocialLinks, Tecnologies } from './Data';

import {AnimatePresence, motion} from 'framer-motion'

function App() {

  const [isActive, setIsActive] = useState(false)
  
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  return (
    <AnimatePresence initial={false}>
    <div className="flex w-screen min-h-screen flex-col items-center justify-center relative bg-primary pb-20">
      {/* navigation Bar*/}
      <nav className='w-full px-6 z-50 fixed inset-x-0 top-2 flex justify-center items-center'>
        <div className='w-full md:w-880 bg-navBar p-4 rounded-2x1 flex items-center'>
          <p className='text-lg text-slate-200 font-medium'>Manuel Servin</p>

          <div className='hidden md:flex items-center gap-6 ml-6 flex-1'>
            <a href='#home' className='text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out'>
              Home
            </a>
            <a href='#about' className='text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out'>
              About
            </a>
            <a href='#projects' className='text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out'>
              Proyectos
            </a>
            <a href='#expirience' className='text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out'>
              Experiencia
            </a>
            <a href='#techs' className='text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out'>
              Tecnologias
            </a>
            <a href='#contact' className='ml-auto text-base text-textBase font-medium hover:text-slate-100 cursor-pointer border border-textBase px-2 py-1 rounded-xl hover:border-gray-100 duration-100 ease-in-out'>
              Contacto
            </a>            
          </div>
          
          <motion.div whileTap={{ scale: 0.5 }} className='block md:hidden ml-auto cursor-pointer' onClick={() => setIsActive(!isActive)}>
            <IoMenu className='text-2x1 text-textBase'/>
          </motion.div>

          {isActive && (
            <motion.div 
              initial={{opacity:0, scale: 0.5}} 
              animate={{opacity:1, scale: 1.1}} 
              exit={{opacity:0, scale: 0.5}} 
              transition={{ delay: 0.005, type: "spring"}}
              className='p-4 w-275 bg-navBar rounded-lg fixed top-24 right-16 flex flex-col items-center justify-evenly gap-6'
            >
              <a href='#home' className='text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out' onClick={() => setIsActive(false)}>
                Home
              </a>
              <a href='#about' className='text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out' onClick={() => setIsActive(false)}>
                About
              </a>
              <a href='#projects' className='text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out' onClick={() => setIsActive(false)}>
                Proyectos
              </a>              
              <a href='#expirience' className='text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out' onClick={() => setIsActive(false)}>
                Experiencia
              </a>
              <a href='#techs' className='text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in-out' onClick={() => setIsActive(false)}>
                Tecnologias
              </a>
              <a href='#contact' className='text-base text-textBase font-medium hover:text-slate-100 cursor-pointer border border-textBase px-2 py-1 rounded-xl hover:border-gray-100 duration-100 ease-in-out' onClick={() => setIsActive(false)}>
                Contacto
              </a>
            </motion.div>
          )}
        </div>
      </nav>

      <div className='w-full h-screen relative' id='home'>
        <Spline scene="https://prod.spline.design/YYWt7X1mhHFK3kjp/scene.splinecode" className='w-full'/>
        <div className='absolute bottom-10 w-full justify-center items-center flex'>
            <div className='shadow-md p-4 flex items-center justify-center border-zinc-900 rounded-3x1'>
              <p className='text-textBase'>Presiona y arrastra para orbitar</p>
            </div>
        </div>
      </div>

      {/* Main sections*/}

      <main className='w-[80%] mt-5'>
            {/* About Secction */}
            <section className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 my-24' id="about">
              {/* image box*/}
              <div className='w-full h-480 flex items-center justify-center'>
                <div className='w-275 h-340 bg-blue-700 rounded-md relative'>
                  <img className='w-full h-full absolute -right-3 top-3 object-cover rounded-lg shadow-lg' src={Logo} alt=""/>
                </div>
              </div>
              
              {/* content box*/}
              <div className='w-full h-480 flex flex-col items-center justify-center'>
                <p className='text-lg text-textBase text-center'>
                  ¡Hola! Como Ingeniero y desarrollador Sr. backend, cuento con amplia experiencia en microservicios, Python, Java, AWS, testing.
                  Y ademas aplico a mis desarrollos buenas practicas y metodologias de desarrollo escalables, como fundamentos de clean code, patrones de diseño, programación funcional y orientada a objetos.
                  También tengo experiencia en Bases de datos relacionales y no relacionales, frameworks como Django, flask, laravel y React.js. Me destaco por mi gran capacidad de trabajo en equipo y por utilizar metodologías ágiles de manera muy eficiente.
                  <br/>
                  <br/>
                  En resumen, cuento con un conjunto de habilidades, experiencia y conocimientos que me permiten asumir con éxito los desafíos del desarrollo backend y contribuir al desarrollo de aplicaciones robustas y escalables.
                </p>
              </div>
            </section>
            
            {/* Projects */}
            <section className='flex flex-wrap items-center justify-evenly my-24 gap-4' id="projects">
              <p className='w-full text-2x1 text-gray-400 capitalize items-center justify-center'>Mis proyectos</p>
              {
                Projects && Projects.map(n => (
                  <div key={n.id} className='border border-zinc-800 rounded-md p-2 min-w-[275px] md:max-w-[275px] hover:border-zinc-600 duration-100 ease-in-out'>
                    <p className='text-md text-textBase font-medium uppercase'>
                        {n.name.length > 25 ? `${n.name.slice(0,25)}...` : n.name}
                    </p>

                      <img src={n.images[getRandomInt(3)]} className='w-full h-full object-cover rounded-md my-4' alt="" />

                    <div className='flex flex-1 items-center justify-between'>
                       <p className='text-lg text-gray-300'>
                          Tecnologies
                          <span className='block text-sm text-gray-500'>
                            {n.techs}
                          </span>
                       </p>

                       {n.links && n.links.map(m => (
                            <a target="_blank" key={m.key} href={m.url} className='p-3 justify-end'>
                              <motion.div
                                className="box"
                                animate={{
                                  scale: [1, 1.6, 1.6, 1, 1],
                                  rotate: [0, 0, 180, 180, 0],
                                  borderRadius: ["0%", "0%", "50%", "50%", "0%"]
                                }}
                                transition={{
                                  duration: 2,
                                  ease: "easeInOut",
                                  times: [0, 0.2, 0.5, 0.8, 1],
                                  repeat: Infinity,
                                  repeatDelay: 1
                                }}                                
                              >
                                  {m.icon}
                              </motion.div>
                            </a>
                          ))
                        }

                    </div>
                  </div>
                ))
              }
            </section>

            {/* Timeline */}
            <p className='w-full text-2x1 text-gray-400 capitalize items-center justify-center'>Mi experiencia</p>
            <section className='w-full flex items-center justify-center' id="expirience">
              <VerticalTimeline>
                  {
                    Experience && Experience.map(n => (
                      <VerticalTimelineElement
                        key={n.id}
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(21, 24, 31)', color: '#888' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(21, 24, 31)' }}
                        date={n.date}
                        iconStyle={{ background: 'rgb(21, 24, 31)', color: '#888' }}
                        icon={n.iconsSrc}
                      >
                        <h3 className="vertical-timeline-element-title">{n.title}</h3>
                        <h4 className="vertical-timeline-element-subtitle">{n.company + " - " + n.location}</h4>
                        <p className='text-blue-500'>
                          {n.description}
                        </p>
                        <h4 className="vertical-timeline-element-text text-orange-400">{n.list_tech}</h4>
                        <h4 className="vertical-timeline-element-text text-yellow-400">Proyecto: {n.project}</h4>
                        <br/>
                        <h4 className="vertical-timeline-element-subtitle text-green-400">Habilidades</h4>
                        {
                          n.list_habilities && n.list_habilities.map(lh => (
                            <ul className="list-disc list-inside">
                              <li>{lh}</li>
                            </ul>
                          ))
                        }
                        <br/>
                        <h4 className="vertical-timeline-element-subtitle text-green-400">Implementaciones</h4>
                        {
                          n.list_implement_techs && n.list_implement_techs.map(li => (
                            <ul className="list-disc list-inside">
                              <li>{li}</li>
                            </ul>
                          ))
                        }
                        <br/>
                        <h4 className="vertical-timeline-element-subtitle text-green-400">Links</h4>
                        {
                          n.links && n.links.map(lk => (
                            <a target="_blank" href={lk.url}>
                                <div className='flex items-center pl-3'>
                                  {lk.description + ": "} {lk.icon}
                                </div>
                            </a>
                          ))
                        }
                      </VerticalTimelineElement>                
                    ))
                  }
              </VerticalTimeline>
            </section>

            {/* Techs */}
            <section className='flex flex-wrap items-center justify-evenly my-24 gap-4' id="techs">
              <p className='w-full text-2x1 text-gray-400 capitalize items-center justify-center'>Tecnologias</p>
              {
                Tecnologies && Tecnologies.map(n => (
                  <div 
                    key={n.id} 
                    className='p-2 min-w-[200px] md:max-w-[200px] hover:border-zinc-600 duration-100 ease-in-out'
                  >
                    <p className='text-md text-textBase font-medium uppercase'>
                        {n.name.length > 25 ? `${n.name.slice(0,25)}...` : n.name}
                    </p>

                    <motion.img 
                      src={n.img_src} 
                      className='w-full h-full object-cover rounded-md my-4' 
                      alt="" 
                      animate={{
                        scale: [1, 1.1, 1.1, 1, 1],
                      }}
                      transition={{
                        duration: 2,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        repeat: Infinity,
                        repeatDelay: 2
                      }}                                
                    />

                  </div>
                ))
              }
            </section>

            {/* Contact */}
            <section className='flex flex-col items-center justify-center w-full my-24' id="contact">
              <p className='text-2x1 text-gray-400 capitalize'>Follow me on</p>

              <div className='flex items-center justify-center w-full my-4 flex-wrap gap-4'>
                {
                  SocialLinks && SocialLinks.map(n => (
                    <motion.a target="_blank" whileTap={{ scale: 0.8 }} key={n.id} href={n.link} className='w-full md:w-auto px-3 md:px-8 py-5 border border-zinc-800 rounded-2x1 hover:border-zinc-600 duration-100 ease-in-out cursor-pointer flex items-center justify-center gap-3'>
                      {n.iconsSrc}
                      <p className='text-lg text-textBase'>{n.name}</p>
                    </motion.a>                    
                  ))
                }
              </div>
            </section>

      </main>
    </div>
    </AnimatePresence>    
  );
}

export default App;
